import Axios from "axios"
import { baseUrl } from "./utils" 



export const getTrips = async (id) => {

    const { data } = await Axios.get(
        baseUrl + "/trip-get-all?user=" + id
      )

    return data
}

export const getTrip = async (tripId) => {

    const { data } = await Axios.get(
        baseUrl + "/trip-get-one?id=" + tripId
      )

    return data
}


export const updateTripCollaborators = async (id, action, email) => {

    console.log('UPDATE', id, action, email)

    const { data } = await Axios.post(
        baseUrl + "/trip-collaborators-update",
        {
            id,
            action,
            email
        }
    )

    return data
}
