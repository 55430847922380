import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Card, CardTitle, Button } from "reactstrap";
import { NavLink  } from "react-router-dom";

import Loading from "../components/Loading";
import Layout from "../components/Layout"
import AddTrip from "../components/TripsPage/AddTrip"
import { useAuth0 } from "../react-auth0-spa";
import { getTrips } from "../../../shared/api/trip"
import Store, {Context} from '../utils/store'

const Profile = () => {
  const { loading, user } = useAuth0();
  const [trips, setTrips] = useState([])

  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    const fetchQuery = async () => {
      if (state && state.user && (!trips || trips.length < 1)) {
        const data = await getTrips(state.user.id)
        console.log(data)
        setTrips(data)
      }
    }
    fetchQuery()
  }, [state.user, trips])

  if (loading || !user) {
    return <Loading />;
  }

  return (
    <Layout>
    <Container className="my-5">

      <div className="mt-5 mb-2 d-flex justify-content-between align-items-center">
        <h1>My Trips</h1>
        <AddTrip buttonLabel="Add Trip" />
      </div>

      {!!trips && !!trips.trips && trips.trips.map((trip) => {
        return <Card body className="mb-3">
          <CardTitle>{trip.name}</CardTitle>
          <NavLink
            to={`/trip/${trip.id}`}
          >
            <Button>View trip planner</Button>
          </NavLink>
          
        </Card>
      })}

      <h1 className="my-2">Shared Trips</h1>

      {!!trips && trips.sharedTrips && trips.sharedTrips.map((trip) => {
        return <Card body className="mb-3">
          <CardTitle>{trip.name}</CardTitle>
          <NavLink
            to={`/trip/${trip.id}`}
          >
            <Button>View trip planner</Button>
          </NavLink>
          
        </Card>
      })}
      
    </Container>
    </Layout>
  );
};

export default Profile;
