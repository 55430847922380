import Axios from "axios"
import { baseUrl } from "./utils" 

export const getUser = async (user) => {
    console.log('User', user)
    const { data } = await Axios.post(
        baseUrl + "/auth0-create-user",
        {
          "email": user.email,
          "auth0Id": user.sub,
          "given_name": user.given_name,
          "family_name": user.family_name,
          "username": user.nickname + "." + Math.round(Math.random() + 100)
        })
    return data
}