import React from 'react'

import DeleteModal from './Delete'
import CollaboratorsModal from './Collaborators'

export default () => {
    return (
        <>
            <DeleteModal />
            <CollaboratorsModal />
        </>
    )
}