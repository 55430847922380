import React, { useState, useEffect } from 'react'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, Image } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const Photos = ({ details }) => {
    const [photos, setPhotos] = useState([])

    useEffect(() => {
        if (details && details.place) {
          const service = new window.google.maps.places.PlacesService(document.createElement('div'));
          service.getDetails(
            {
              placeId: details.place.placeId
            },
            (data, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK && data.photos) {
                  setPhotos(data.photos.map(photo => ({
                    url: photo.getUrl({ maxWidth: 500, maxHeight: 500 })
                  })))
              }
            }
          );
        } else {
          setPhotos([])
        }
      }, [details.place])

    if (!photos || !photos.length) return null

    return (
            <CarouselProvider
            naturalSlideWidth={300}
            naturalSlideHeight={200}
            totalSlides={photos.length}
            className="border-bottom"
          >
            <Slider>
              {photos.map((photos, index) => 
                <Slide index={index} key={index}>
                  <Image src={photos.url} />
                </Slide>)}
            </Slider>

            <ButtonBack className="slider-btn">
              <i className="fas fa-chevron-left"></i>
            </ButtonBack>
            <ButtonNext  className="slider-btn right">
              <i className="fas fa-chevron-right"></i>
            </ButtonNext>
          
          </CarouselProvider>
    )
}

export default Photos