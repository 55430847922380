import React, { useEffect, useState, useContext } from "react";
import { Input } from "reactstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import Axios from "axios"

import {Context} from '../../utils/store'
import { isShared } from "../../utils/share"

const PlaceSearch = (props) => {

  const [state, dispatch] = useContext(Context);
  const [address, setAddress] = useState("")

  if (isShared) return null

  const handleChange = address => {
    setAddress(address);
  };
 
  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        console.log(results[0])
        var service = new window.google.maps.places.PlacesService(document.createElement('div'));
          service.getDetails({
            placeId: results[0].place_id
          }, function (place, status) {
            console.log('Place details:', place);

            const payload = {
              type: "PLACE",
              name: place.name,
              note: "",
              icon: place.icon,
              place: {
                address: place.formatted_address,
                location: {
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng()
                },
                placeId: place.place_id,
                rating: place.rating,
                googleMapsUrl: place.url,
                user_ratings_total: place.user_ratings_total,
              },
              fromSearch: true
            }

            console.log('details', payload)

            dispatch({
              type: 'SET_PLACE_DETAILS',
              payload: payload
            })
          });
      })
      .catch(error => console.error('Error', error));
  };


  return (
    <div className="placeSearch">

      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>

    </div>
  );
};

export default PlaceSearch;
