import React from 'react'
import { Button, Card, CardBody, CardImg, CardLink, CardSubtitle } from "reactstrap";

import Section from "./Section"
import Editable from "../../Tools/Editable"
import EditableList from "../../Tools/EditableList"
import { getCenter } from "../../../../../shared/components/Map/MapRenderer"

const Lodging = ({ details: { lodging, id }, onLodgingFieldSave }) => {

    if (!lodging) return null

    return (<>
        <Section title="Lodging details" open={true} hide={!lodging}>
            <Editable content={lodging.nights} field="nights" type="number" onSave={onLodgingFieldSave} inline icon>
                <b>Nights: </b> {lodging.nights}
            </Editable>
            <Editable content={lodging.url} field="url" onSave={onLodgingFieldSave} inline icon>
                <a href={lodging.url} target="_blank"><Button color="primary">Search on Airbnb</Button></a>
            </Editable>
        </Section>

        <Section title="Lodging options" open={true} hide={!lodging}>
            <EditableList
                parentId={lodging.id}
                parentType="Lodging" 
                elementId={id}
                itemField="options"
                itemType="LodgingOptions"
                defaultItem={{ 
                    img: "",
                    address: "",
                    location: {
                        create: getCenter().location
                    },
                    price: 0,
                    url: "",
                    platform: "Airbnb",
                    booked: false
                }}
                list={lodging.options}
                editableFields={lodging.options.map((opt) => ([
                    { name: 'img', value: opt.img },
                    // { name: 'address', value: opt.address },
                    { name: 'price', value: opt.price, type: 'number' },
                    { name: 'url', value: opt.url },
                    // { name: 'platform', value: opt.platform },
                ]))}
                ListRenderer={({item, Edit}) => (
                    <Card className="mb-2">
                        {item.img && <CardImg top width="100%" src={item.img} alt="Lodging image from Airbnb" />}
                        <CardBody>
                            <CardSubtitle className="mb-2">{item.price}$ / night ({item.price * lodging.nights}$ total)</CardSubtitle>
                            <CardLink href={item.url} target="_blank">
                                <Button color="primary" size="sm" outline>Open in {item.platform}</Button>
                            </CardLink>
                            <Edit className="ml-1" />
                        </CardBody>
                    </Card>)}
                />
        </Section>
    </>)
}

export default React.memo(Lodging)