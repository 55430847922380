import React from 'react';
import BaseModal from './index'

const DeleteModal = props => {
    return (
        <BaseModal
            name="DELETE"
            title="Confirm deletion"
            body="Please confirm your want to delete this item."
            actionText="Confirm"
            actionColor="danger" />
    )
}

export default DeleteModal