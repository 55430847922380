import React, { useContext, useState } from 'react';
import { Input, Button, Spinner, Card, CardTitle } from 'reactstrap'

import BaseModal from './index'
import {Context} from '../../utils/store'
import { updateTripCollaborators } from "../../../../shared/api/trip"

const Collaborators = props => {
    const [state, dispatch] = useContext(Context);
    const [value, setValue] = useState("")
    const [loading, setLoading] = useState(false)

    if (!state || !state.trip) return null

    async function save() {
        setLoading(true)
        const {trip} = await updateTripCollaborators(state.trip.id, "ADD", value)
        console.log("NEW TRIP", trip)
        dispatch({
            type: 'SET_TRIP',
            payload: trip
        })
        setLoading(false)
    }

    async function remove(email) {
        const {trip} = await updateTripCollaborators(state.trip.id, "REMOVE", email)
        dispatch({
            type: 'SET_TRIP',
            payload: trip
        })
    }

    const body = (<div>
        <div className="d-flex justify-content-between mb-2">
            <Input
                type="text"
                placeholder="Enter an email address"
                value={value}
                onChange={(e) => setValue(e.target.value)} />
            <Button color="primary" className="ml-2" onClick={save}>
                {loading ? <Spinner size="sm" color="light" /> : "Add"}
            </Button>
        </div>

        <div className="mt-2">
            {state.trip.collaborators.map(collaborator => {
                return <Card body className="mb-2">
                    <CardTitle>{collaborator.given_name} ({collaborator.email})</CardTitle>
                    <Button color="danger" size="sm" onClick={() => remove(collaborator.email)}>Remove</Button>
                </Card>
            })}
        </div>
    </div>)

    return (
        <BaseModal
            name="COLLABORATORS"
            title="Collaborators"
            body={body}
            actionText="Close" />
    )
}

export default Collaborators