import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Card, CardTitle, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Axios from "axios";

import List from "../../../../shared/components/Elements/List"
import { addElement } from "../../../../shared/api/elements"
import { getCenter } from "../../../../shared/components/Map/MapRenderer"
import {Context} from '../../utils/store'
import { isShared } from "../../utils/share"

const PlaceElement = () => {
  const [state, dispatch] = useContext(Context);

  if (!state || !state.trip) return null

  const { name } = state.trip

  async function createNewElement(type, content) {
    content.index = state.elements.length + 2
    const {element} = await addElement(state.trip.id, type, content, state.placeDetails)
    console.log("ELEMENT TO ADD", element)
    dispatch({
      type: 'ADD_ELEMENT',
      payload: element
    })
    dispatch({
      type: 'SET_PLACE_DETAILS',
      payload: element
    })
  }

  function createNote() {
    createNewElement('NOTE', {
      name: 'Note',
      note: 'Content of the note to edit.'
    })
  }

  function createLodging() {
    createNewElement('NOTE', {
      name: 'Lodging',
      subtype: 'LODGING'
    })
  }

  function createDirections() {
    createNewElement('DIRECTIONS', {
      name: 'Itinerary'
    })
  }

  function createGroup() {
    createNewElement('GROUP', {
      name: 'Group'
    })
  }

  async function openModalCollaborator() {
    dispatch({
      type: 'MODAL',
      payload: {
        name: 'COLLABORATORS'
      }
    })
  }

  const saveDefault = async () => {
    await Axios.post(
      window.location.origin + "/.netlify/functions/trip-update",
      {
        id: state.trip.id,
        data: {
          location: {
            upsert: {
              create: getCenter().location,
              update: getCenter().location
            }
          },
          zoom: getCenter().zoom
        }
      })
  }

  let tMin = 0, tMax = 0
  if (state.elements && state.elements.length) {
    for (let elem1 of state.elements) {
      if (elem1.type === 'GROUP' && elem1.childs.length > 0) {
        for (let elem of elem1.childs) {
          if (elem.lodging) {
            let min = Infinity, max = 0
            for (let option of elem.lodging.options) {
              if (option.price !== 0 && option.price * elem.lodging.nights < min) min = option.price * elem.lodging.nights
              if (option.price !== 0 && option.price * elem.lodging.nights > max) max = option.price * elem.lodging.nights
            }
            tMin += min
            tMax += max
          }
        }
      }
    }
  }

  return (
    <Card body className="placeTripCard scrollable pb-4">

      <div className="d-flex justify-content-between align-items-center">
        <h1>{name}</h1>
        {!isShared && <UncontrolledDropdown>
          <DropdownToggle tag="a" className="nav-link">
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={openModalCollaborator}><i className="fas fa-user-friends"></i> Collaborators</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={saveDefault}><i className="fas fa-map-marker-alt"></i> Set default view</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>}
      </div>

      {!isShared && <div className="mt-2 mb-2 position-sticky" style={{ top: 0, zIndex: 10 }}>
        <UncontrolledDropdown>
          <DropdownToggle caret color="primary">
            Add element
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={createDirections}><i className="fas fa-car-side"></i> Itinerary</DropdownItem>
            <DropdownItem onClick={createNote}><i className="fas fa-bus"></i> Transportaion</DropdownItem>
            <DropdownItem onClick={createNote}><i className="fas fa-hiking"></i> Walk / Hike</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={createLodging}><i className="fas fa-bed"></i> Lodging</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={createNote}><i className="fas fa-sticky-note"></i> Note</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={createGroup}><i className="fas fa-layer-group"></i> Group</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>}

      <div className="mb-3">
        Lodging total: {tMin}$ - {tMax}$ 
      </div>
      
      <div className="mb-1">
        {state.elements.length > 0 && <List elements={state.elements} parent={null} />}
      </div>

    </Card>
  );
};

export default PlaceElement;
