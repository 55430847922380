import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Card, CardTitle, Button } from "reactstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import Axios from "axios"

import PlaceSearch from "../components/Planner/PlaceSearch"
import PlaceDetailsCard from "../components/Planner/PlaceDetailsCard"
import PlaceElements from "../components/Planner/PlaceElements"
import PlaceMap from "../components/Planner/PlaceMap"

import { getTrip } from "../../../shared/api/trip"
import {Context} from '../utils/store'

const Trip = (props) => {
  const [state, dispatch] = useContext(Context)

  useEffect(() => {
    const fetchQuery = async () => {
        const data = await getTrip(props.match.params.id)
        dispatch({
          type: 'SET_TRIP',
          payload: data.trip
        })

        dispatch({
          type: 'SET_ELEMENTS',
          payload: data.trip.elements
        })
    }
    fetchQuery()
  }, [props.match.params.id])

  return (
    <>
      <div className="map">

        <PlaceSearch />

        <PlaceElements />

        <div className="scrollable">
          <PlaceDetailsCard />
        </div>

        
        <PlaceMap />

      </div>
    </>
  );
};

export default Trip;
