import React, { useState, useContext } from 'react';
import { Input, Button, Spinner, Label } from 'reactstrap'

import { isShared } from "../../utils/share"
import { addListElement, updateListElement } from "../../../../shared/api/element-list"
import {Context} from '../../utils/store'

const EditableList = ({ list, ListRenderer, defaultItem, itemField, itemType, parentType, parentId, elementId, editableFields }) => {
    const [state, dispatch] = useContext(Context);
    const [isEditing, setEditing] = useState(null)
    const [values, setValues] = useState({})
    const [fields, setFields] = useState({})
    const [loading, setLoading] = useState(false)

    const edit = (index) => {
        setEditing(list[index].id)
        
        setValues(editableFields[index].reduce((prev, curr, index, arr) => {
            prev[curr.name] = curr.value
            return prev
        }, {}))

        setFields(editableFields[index])
    }

    const cancel = () => {
        setEditing(null)
    }

    async function updateContent(element) {
        console.log("ELEMENT TO REPLACE", element)
        dispatch({ type: 'SET_PLACE_DETAILS', payload: element })
        dispatch({
          type: 'REPLACE_ELEMENT',
          payload: element
        })
      }

    const add = async () => {
        console.log("ADD ITEM", defaultItem)
        setLoading(true)
        const {element} = await addListElement(itemField, defaultItem, parentType, parentId, elementId)
        await updateContent(element)
        setLoading(false)
    }

    const save = async (id) => {
        console.log("UPDATE ITEM", values)
        setLoading(true)
        values.id = id
        const {element} = await updateListElement(itemType, values, elementId)
        await updateContent(element)
        setEditing(null)
        setLoading(false)
    }

    const EditableRenderer = ({ id }) => {
        console.log('EDITABLE', fields)

        return <div className="mb-1 w-100">

            {fields.map(({name, value, type, ...props}, index) => {
                return <div key={index}>
                    <Label>{name}</Label>
                    <Input
                        placeholder={name}
                        type={type || "text"}
                        value={values[name]}
                        onChange={(e) => setValues({...values, [name]: type === "number" ? parseFloat(e.target.value) : e.target.value})}
                        className="mb-2 w-100"
                        {...props} />
                </div>
            })}

            <Button size="sm" color="primary" onClick={() => save(id)}>
                {loading ? <Spinner size="sm" color="light" /> : "Save"}
            </Button>
            <Button size="sm" color="secondary" onClick={cancel} className="ml-2">
                Cancel
            </Button>
        </div>
    }

    return (<>
        {list.length == 0 && <small>Add an element to the list.</small>}
        <div>{list.map((item, index) => {
            if (item.id === isEditing) return <EditableRenderer key={index} id={item.id} />

            return (
                <div key={index}>
                <ListRenderer 
                    item={item}
                    Edit={(props) => <Button size="sm" color="link" onClick={() => edit(index)} className="p-0 pointer" {...props}>Edit</Button>} />
                </div>
            )

        })}</div>
        {!isShared && <div>
            <Button size="sm" color="primary" onClick={add} className="mt-2">Add</Button>
        </div>}
    </>   
    )
}

export default EditableList

