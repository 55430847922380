import React, { Fragment } from "react";

import Layout from "../components/Layout"
import HomeContent from "../components/Home"

const Home = () => (
  <Layout>
    <HomeContent />
  </Layout>
);

export default Home;
