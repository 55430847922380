import React from "react";
import mapboxgl from "mapbox-gl";
import Axios from "axios"

mapboxgl.accessToken =
  "pk.eyJ1IjoibG9pYzI5NCIsImEiOiJjaW54a3IwcWYxNzhodWFtMzc4eGs2Y2V4In0.IhCIftgKJJBi8gTnI4_8ng";

let markers = new Map()
let eventListeners = new Map()
let markerDetails = new Map()

let queue = []

export let map = null;

class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: props.defaultPosition ? props.defaultPosition.location : [5,34],
      zoom: props.defaultPosition ? props.defaultPosition.zoom : 2
    };
  }

  componentDidMount() {
    console.log("MAP COMPONENT MOUNTED AGAIN")

    markers = new Map()
    eventListeners = new Map()
    markerDetails = new Map()

    map = new mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/mapbox/streets-v11",
      center: this.state.center,
      zoom: this.state.zoom
    });

    var nav = new mapboxgl.NavigationControl({ showCompass: false });
    map.addControl(nav, 'bottom-right');

    map.on("load", () => {
      console.log("Processing queue")
      let elem = queue.pop()
      while (elem) {
        if (elem.task == "directions") {
          addDirectionsInternal(elem.data.id, elem.data.data, elem.data.color)
        }

        if (elem.task == "marker") {
          addMarker(elem.data.id, elem.data.location, elem.data.color, elem.data.elem, elem.data.onClick)
        }

        
        elem = queue.pop()
      }

    })

    map.on("move", () => {
      if (this.props.onMove)
        this.props.onMove({
          location: map.getCenter(),
          zoom: map.getZoom()
        })
    });
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <div ref={el => (this.mapContainer = el)} className="mapContainer" />
    );
  }
}

export const flyTo = (location, zoom) => {
  map.flyTo({
    center: location,
    zoom: zoom,
    bearing: 0,
    speed: 2, // make the flying slow
    curve: 1, // change the speed at which it zooms out
    easing: function(t) {
      return t;
    },
    essential: true
  });
};

export const addMarker = (id, location, color = "#3FB1CE", elem = null, onClick = () => null) => {
  console.log('ADD MARKER')
  if (!map) {
    queue.push({
      task: 'marker',
      data: {id, location, color, elem, onClick}
    })
  }
  if (markers.has(id)) {
    updateMarker(id, location)
    return
  }
  let marker = new mapboxgl.Marker({
    color: color
  })
  marker
    .setLngLat(location)
    .addTo(map)

  const eventListener = () => {
    onClick(elem)
  }

  marker.getElement().addEventListener('click', eventListener);

  markers.set(id, marker)
  eventListeners.set(id, eventListener)
  markerDetails.set(id, { elem, onClick })
};

export const updateMarker = (id, location) => {
  if (!markers.has(id)) {
    addMarker(id, location)
    return
  }

  let marker = markers.get(id)
    marker
      .setLngLat(location)
}

export const updateMarkerColor = (id, color = "#3FB1CE") => {
  if (!markers.has(id)) {
    return
  }

  let marker = markers.get(id)
  let { elem, onClick } = markerDetails.get(id)
  const location = marker.getLngLat()

  removeMarker(id)
  addMarker(id, location, color, elem, onClick)
}

export const removeMarker = (id) => {
  if (markers.has(id)) {
    markers.get(id).remove()
    markers.get(id).getElement().removeEventListener('click', eventListeners.get(id));
    markers.delete(id)
    markerDetails.delete(id)
    eventListeners.delete(id)
  }
}

export const addDirections = (id, data, color) => {
  if (map.isStyleLoaded()) {
    addDirectionsInternal(id, data, color)
  } else {
    queue.push({
      task: 'directions',
      data: {id, data, color}
    })
  }
}

const addDirectionsInternal = (id, data, color = '#3887be') => {
  var geojson = {
    type: 'Feature',
    properties: {},
    geometry: data
  };

  if (map.getSource(id)) {
    map.getSource(id).setData(geojson);
  } else {
    map.addLayer({
      id: id,
      type: 'line',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: geojson
          }
        }
      },
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': color || '#3887be',
        'line-width': 5,
        'line-opacity': 0.75
      }
    });

    map.getSource(id).setData(geojson);
  }
}

export const removeDirection = (id) => {
  if (map.getSource(id)) {
    map.removeLayer(id)
  }
}

export const getCenter = () => {
  return {
    location: map.getCenter(),
    zoom: map.getZoom()
  }
}

export default MapComponent;
