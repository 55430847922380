import React, { useState, useEffect } from 'react';
import { Input, Button, Spinner, Link } from 'reactstrap'

import { isShared } from "../../utils/share"

const Editable = props => {
    const [isEditing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(props.content)

    useEffect(() => {
        setValue(props.content)
    }, [props.content])

    const save = async () => {
        setLoading(true)
        if (props.onSave) await props.onSave(props.field, value)
        setEditing(false)
        setLoading(false)
    }

    const edit = () => {
        setEditing(true)
    }

    const cancel = () => {
        setEditing(false)
    }

    if (isEditing) {
        return <div className="mb-1 w-100">
            <Input
                type={props.type || "text"}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="mb-2 w-100"
                {...props.inputProps} />

            <Button size="sm" color="primary" onClick={save}>
                {loading ? <Spinner size="sm" color="light" /> : "Save"}
            </Button>
            <Button size="sm" color="secondary" onClick={cancel} className="ml-2">
                Cancel
            </Button>
        </div>
    }

    return (
        <div className={props.inline ? "d-flex align-items-center" : ""}>
            <div>{props.children || props.content}</div>
            {!isShared && <Button size="sm" color="link" onClick={edit} className={props.inline ? "pointer" : "p-0 pointer"}>
                {props.icon ? <i className="far fa-edit"></i> : "Edit"}
            </Button>}
        </div>
    )
}

export default Editable

