const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                user: action.payload
            };
        case 'SET_PLACE_DETAILS':
            return {
                ...state,
                placeDetails: action.payload
            };
        case 'SET_TRIP':
            return {
                ...state,
                trip: action.payload
            };
        case 'SET_ELEMENTS':
            return {
                ...state,
                elements: action.payload
            };
        case 'ADD_ELEMENT':
            if (state.placeDetails && state.placeDetails.type == "GROUP") {
                const index3 = state.elements.findIndex((elem) => elem.id == state.placeDetails.id)
                state.elements[index3].childs = [...state.elements[index3].childs, action.payload]  
                return {
                    ...state,
                    elements: state.elements
                }
            }
            return {
                ...state,
                elements: [...state.elements, action.payload]
            };
        case 'REPLACE_ELEMENT':
            return {
                ...state,
                elements: replaceElement(state.elements, action.payload)
            };
        case 'REMOVE_ELEMENT':
            return  {
                ...state,
                elements: removeElement(state.elements, action.payload)
            };  
        case 'MODAL':
            return {
                ...state,
                modal: action.payload
            };
        default:
            return state;
    }
};

function replaceElement(elements, newElement) {

    function goThrough(elems) {
        return elems.map((elem) => {
            if (elem.childs) {
                elem.childs = goThrough(elem.childs)
            }
            if (elem.id === newElement.id) return newElement
            return elem
        })
    }

    return goThrough(elements)
}

function removeElement(elements, oldElement) {

    function goThrough(elems) {
        
        return elems
            .filter((elem) => elem.id != oldElement.id)
            .map((elem) => {
                if (elem.childs && elem.childs.length) elem.childs = goThrough(elem.childs)
                return elem
            })
    }

    return goThrough(elements)
}

export default Reducer;