import React, { useEffect, useState, useContext } from "react";
import {
  Input,
  Button,
  Label,
  Form,
  FormGroup,
  Card,
  CardText,
  CardTitle,
  UncontrolledCollapse,
  CustomInput
} from "reactstrap";
import {
  SingleDatePicker,
  ANCHOR_RIGHT
} from "react-dates";
import polyline from "@mapbox/polyline";
import moment from "moment";

import { updateElement } from "../../../../../shared/api/elements";
import { Context } from "../../../utils/store";
import LocationSearch from "../../Tools/LocationSearch";
import Editable from "../../Tools/Editable";
import Section from "./Section";
import { isShared } from "../../../utils/share";

const ItineraryDetails = props => {
  const [state, dispatch] = useContext(Context);
  const [stateFrom, setFrom] = useState({});
  const [stateTo, setTo] = useState({});
  const [transportMode, setMode] = useState("DRIVING");
  const [departureTime, setDepartureTime] = useState();
  const [departureDate, setDepartureDate] = useState();
  const [departureFocus, setDepartureFocus] = useState();
  const [routes, setRoutes] = useState([]);
  const [modes, setModes] = useState([
    "BUS",
    "RAIL",
    "SUBWAY",
    "TRAIN",
    "TRAM"
  ]);

  console.log("DIRECTIONS", state.placeDetails);

  const {
    id,
    directions: {
      distance,
      duration,
      mode,
      from,
      from_name,
      to,
      to_name,
      leaveAt,
      route
    }
  } = state.placeDetails;

  useEffect(() => {
    if (mode != transportMode) setMode(mode);
  }, [mode]);

  useEffect(() => {
    if (from)
      setFrom({
        location: { lat: from.lat, lng: from.lng },
        address: from_name
      });
  }, [from_name]);

  useEffect(() => {
    if (to) setTo({ location: { lat: to.lat, lng: to.lng }, address: to_name });
  }, [to_name]);

  useEffect(() => {
    if (leaveAt) {
      setDepartureTime(moment(leaveAt).format("HH:mm"));
      setDepartureDate(moment(leaveAt));
    }
  }, [leaveAt]);

  async function updateContent(data) {
    const { element } = await updateElement(id, data);
    dispatch({
      type: "REPLACE_ELEMENT",
      payload: element
    });
    dispatch({ type: "SET_PLACE_DETAILS", payload: element });
  }

  async function onFieldSave(field, value) {
    await updateContent({
      directions: {
        update: { [field]: value }
      }
    });
  }

  async function onFloatFieldSave(field, value) {
    await updateContent({
      directions: {
        update: { [field]: parseFloat(value) }
      }
    });
  }

  async function calculate() {
    var directionsService = new window.google.maps.DirectionsService();
    var request = {
      origin: stateFrom.place.formatted_address,
      destination: stateTo.place.formatted_address,
      travelMode: window.google.maps.TravelMode[transportMode],
      provideRouteAlternatives: true
    };

    if (transportMode == "TRANSIT") {
      console.log("TIMEZONE", state.trip.timezone);
      const departure =
        departureTime && departureDate
          ? moment(
              `${moment(departureDate).format("DD/MM/YYYY")} ${departureTime}`,
              "DD/MM/YYYY HH:mm"
            ).utcOffset(state.trip.timezone, true)
          : undefined;
      console.log("DEPARTURE TIME", departure.toISOString());
      request.transitOptions = {
        departureTime: departure.toDate(),
        modes: modes
      };
    }

    const findRoute = request => {
      return new Promise((resolve, reject) => {
        directionsService.route(request, function(response, status) {
          if (status == "OK") {
            resolve(response);
          } else {
            reject(response);
          }
        });
      });
    };

    const data = await findRoute(request);
    console.log("GOOGLE MAPS RESPONSE", data);

    if (data.routes.length == 0) {
      alert("No routes found");
      return;
    }

    setRoutes(data.routes);

    const route = data.routes[0];
    await saveRoute(route);
  }

  async function saveRoute(route) {
    const geojson = polyline.toGeoJSON(route.overview_polyline);

    await updateContent({
      directions: {
        update: {
          to: {
            upsert: {
              update: stateTo.location,
              create: stateTo.location
            }
          },
          from: {
            upsert: {
              update: stateFrom.location,
              create: stateFrom.location
            }
          },
          mode: transportMode,
          from_name: stateFrom.address,
          to_name: stateTo.address,
          duration: route.legs[0].duration.text,
          distance: route.legs[0].distance.text,
          geojson: geojson,
          route: route,
          leaveAt:
            departureDate && departureTime
              ? moment(
                  `${moment(departureDate).format(
                    "DD/MM/YYYY"
                  )} ${departureTime}`,
                  "DD/MM/YYYY HH:mm"
                ).toISOString()
              : undefined
        }
      }
    });
  }

  const toggleMode = mode => {
    if (modes.includes(mode)) {
      setModes(modes.filter(m => m != mode));
    } else {
      setModes([...modes, mode]);
    }
  };

  return (
    <div className="mb-2">
      <Section title="Route" open={true} hide={!route}>
        <RenderRoute routes={[route]} saveRoute={saveRoute} canSave={false} />
      </Section>

      <Section title="Details" open={true}>
        <Editable content={distance} field="distance" onSave={onFloatFieldSave} />
        <Editable content={duration} field="duration" onSave={onFieldSave} />
      </Section>

      <Section title="Search" open={false} hide={isShared}>
        <Form>
          <FormGroup>
            <Label>Transportaion Mode</Label>
            <Input type="select" onChange={e => setMode(e.target.value)}>
              <option value="DRIVING" selected={transportMode == "DRIVING"}>
                Driving
              </option>
              <option value="TRANSIT" selected={transportMode == "TRANSIT"}>
                Transit
              </option>
              <option value="WALKING" selected={transportMode == "WALKING"}>
                Walking
              </option>
              <option value="BICYCLING" selected={transportMode == "BICYCLING"}>
                Bicycling
              </option>
            </Input>
          </FormGroup>

          {transportMode == "TRANSIT" && (
            <FormGroup>
              <Label>Leave at</Label>

              <div className="d-flex align-items-center">
                <SingleDatePicker
                  date={departureDate}
                  onDateChange={setDepartureDate}
                  focused={departureFocus}
                  onFocusChange={({ focused }) => setDepartureFocus(focused)}
                  id="datepicker_directions"
                  showClearDates={true}
                  small={true}
                  appendToBody={true}
                  anchorDirection={ANCHOR_RIGHT}
                />

                <Input
                  type="time"
                  onChange={e => setDepartureTime(e.target.value)}
                  value={departureTime}
                  className="ml-2"
                />
              </div>
            </FormGroup>
          )}

          {transportMode == "TRANSIT" && (
            <FormGroup>
              <Label>Transit options</Label>
              <div>
                <CustomInput
                  type="switch"
                  id="busSwitch"
                  name="busSwitch"
                  onChange={() => toggleMode("BUS")}
                  checked={modes.includes("BUS")}
                  label="Bus"
                />
                <CustomInput
                  type="switch"
                  id="railSwitch"
                  name="railSwitch"
                  onChange={() => toggleMode("RAIL")}
                  checked={modes.includes("RAIL")}
                  label="Rail"
                />
                <CustomInput
                  type="switch"
                  id="subwaySwitch"
                  name="subwaySwitch"
                  onChange={() => toggleMode("SUBWAY")}
                  checked={modes.includes("SUBWAY")}
                  label="Subway"
                />
                <CustomInput
                  type="switch"
                  id="trainSwitch"
                  name="trainSwitch"
                  onChange={() => toggleMode("TRAIN")}
                  checked={modes.includes("TRAIN")}
                  label="Train"
                />
                <CustomInput
                  type="switch"
                  id="tramSwitch"
                  name="tramSwitch"
                  onChange={() => toggleMode("TRAM")}
                  checked={modes.includes("TRAM")}
                  label="Tram"
                />
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="from">From</Label>
            <LocationSearch
              id="from"
              address={stateFrom.address}
              onSelected={setFrom}
            />
          </FormGroup>

          <FormGroup>
            <Label for="to">To</Label>
            <LocationSearch
              id="to"
              address={stateTo.address}
              onSelected={setTo}
            />
          </FormGroup>

          <Button color="primary" block onClick={calculate}>
            Calculate
          </Button>
        </Form>
      </Section>

      <Section title="Routes" open={true} hide={isShared}>
        <RenderRoute routes={routes} saveRoute={saveRoute} canSave={true} />
      </Section>
      
    </div>
  );
};

function RenderRoute({ routes, saveRoute, canSave = false }) {
  if (!routes || !routes.length) return <small>No routes to render</small>

  return (
    <>
      {routes.map((route, index) => {
        const leg = route.legs[0];
        const steps = leg.steps;
        return (
          <Card outline color="primary" className="p-2 mb-2" key={index}>
            <CardTitle className="mb-1">
              {steps.filter((step) => step.travel_mode == "TRANSIT").map((step, index) => (
                <img src={step.transit.line.vehicle.icon} style={{ maxHeight: 25 }} key={index} />
              ))}
              &nbsp;
              <b>{leg.duration.text}</b> {leg.distance.text} 
              <br />
              {leg.departure_time && (
                <small>
                  {leg.departure_time.text} - {leg.arrival_time.text}
                </small>
              )}
            </CardTitle>
            {canSave && (
              <Button color="primary" size="sm" onClick={() => saveRoute(route)}>
                Select
              </Button>
            )}
            <Button color="primary" outline size="sm" id={`route${index}`}>
              Details
            </Button>

            <UncontrolledCollapse toggler={`#route${index}`} className="mt-2">
              {steps.map((step, index) => (
                <div key={index}>
                  <Card body className="mb-1">
                    <CardTitle>
                      <img
                        src={
                          step.transit ? step.transit.line.vehicle.icon : null
                        }
                      />{" "}
                      <b>{step.duration.text}</b> {step.distance.text} (
                      {step.travel_mode})
                    </CardTitle>
                    <CardText
                      dangerouslySetInnerHTML={{ __html: step.instructions }}
                    />
                    {step.travel_mode == "TRANSIT" && (
                      <small>
                        <b>From:</b> {step.transit.departure_stop.name} -{" "}
                        {step.transit.departure_time.text}
                        <br />
                        <b>To:</b> {step.transit.arrival_stop.name} -{" "}
                        {step.transit.arrival_time.text} <br />
                        <b>Line:</b>{" "}
                        <span style={{ color: step.transit.line.color }}>
                          {step.transit.line.name}
                        </span>{" "}
                        <br />
                        <b>Via:</b> {step.transit.line.vehicle.name} (
                        {step.transit.line.vehicle.type}){" "}
                        <b>{step.transit.num_stops} stops</b> <br />
                      </small>
                    )}
                  </Card>
                </div>
              ))}
            </UncontrolledCollapse>
          </Card>
        );
      })}
    </>
  );
}

export default ItineraryDetails;
