import React, { useEffect, useState, useContext } from "react";

import {Context} from '../../utils/store'
import Map, { flyTo, addMarker, removeMarker, addDirections } from "../../../../shared/components/Map/MapRenderer"
import { getDirections } from "../../../../shared/api/elements"

const fetchedCache = new Set()

const PlaceMap = () => {
  const [state, dispatch] = useContext(Context)
  const [defaultPosition, setDefaultPosition] = useState("")

  const [lastPoint, setLastPoint] = useState("")

  function selectMarker(payload) {
    dispatch({
      type: 'SET_PLACE_DETAILS',
      payload: payload
    })
  }

  if (state && state.placeDetails && state.placeDetails.place && lastPoint != state.placeDetails.place.placeId) {
    setLastPoint(state.placeDetails.place.placeId)
    console.log(state.placeDetails.location)
    flyTo(
      state.placeDetails.place.location,
      8
    )
    addMarker(
      'details',
      state.placeDetails.place.location,
      "#FFC837")
  }

  if (state && (!state.placeDetails || !state.placeDetails.place) && lastPoint != null) {
    setLastPoint(null)
    removeMarker('details')
  }

  function addMarkersFromElements(elements, color = null) {
    if (!elements) return

    elements.map((elem) => {
      if (elem.type == "PLACE") {
        addMarker(
          elem.id,
          {lat: elem.place.location.lat, lng: elem.place.location.lng },
          elem.color && elem.color.length > 0 ? elem.color : color, 
          elem,
          selectMarker
        )
      }

      if (elem.type == "DIRECTIONS" && elem.directions) {
        console.log("HAS DIRECTIONS", elem)
        if (!fetchedCache.has(elem.id)) {
          console.log("GETTING DIRECTIONS", elem.directions)
          getDirections(elem.directions.id).then((directions) => {
            addDirections(
              elem.id,
              directions.geojson,
              elem.color && elem.color.length > 0 ? elem.color : color
            )
            fetchedCache.add(elem.id)
          })
        }
        
       
      }

      if (elem.type == 'GROUP' && elem.childs) {
        addMarkersFromElements(elem.childs, elem.color)
      }
    })
  }

  useEffect(() => {
    addMarkersFromElements(state.elements)
  }, [state.elements])

  if (!state.trip) return null

  return (
    <>
      <Map
        defaultPosition={{
          location: state.trip && state.trip.location ? [state.trip.location.lng, state.trip.location.lat] : [0,0],
          zoom: state.trip.zoom
        }}
        onMove={(data) => setDefaultPosition(data)} />
    </>
  );
};

export default PlaceMap;
