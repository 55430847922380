import React from 'react'
import Linkify from 'react-linkify'

import Editable from "../../Tools/Editable"

const Note = ({ details: { note }, onFieldSave }) => {

    return (
        <Editable content={note} field="note" type="textarea" inputProps={{ rows: 7 }} onSave={onFieldSave}>
            <Linkify>{note}</Linkify>
            {(!note || !note.length) && "Add a note."}
        </Editable>
    )
}

export default Note