import React, { useEffect, useState, useContext } from "react";
import { Card, Button } from "reactstrap";
import { addElement, updateElement } from "../../../../shared/api/elements"

import {Context} from '../../utils/store'
import Section from "./Details/Section"
import Editable from "../Tools/Editable"
import ItineraryDetails from "./Details/ItineraryDetails"
import DatesDetails from "./Details/Dates"
import ColorsDetails from "./Details/Colors"
import IconDetails from "./Details/Icon"
import Photos from "./Details/Photos"
import Delete from "./Details/Delete"
import Note from "./Details/Note"
import Place from "./Details/Place"
import Nights from "./Details/Nights"
import Lodging from "./Details/Lodging"
import { isShared } from "../../utils/share"

const PlaceDetailsCard = () => {
  
  const [state, dispatch] = useContext(Context);
  const [oldId, setOldId] = useState("")

  if (!state || !state.placeDetails) return null

  const {
    id,
    type,
    name,
    note,
    icon,
    place,
    fromSearch,
    directions
  } = state.placeDetails

  if (oldId != id) {
    setOldId(id)
  }

  function close() {
    dispatch({ type: 'SET_PLACE_DETAILS', payload: null })
  }

  async function createElement() {
    const place = state.placeDetails
    place.index = state.elements.length + 2
    const {element} = await addElement(state.trip.id, 'PLACE', place)
    console.log("ELEMENT TO ADD", element)
    dispatch({
      type: 'ADD_ELEMENT',
      payload: element
    })
    dispatch({ type: 'SET_PLACE_DETAILS', payload: element })
  }

  async function updateContent(data) {
    const {element} = await updateElement(id, data)
    console.log("ELEMENT UPDATED", element)
    dispatch({
      type: 'REPLACE_ELEMENT',
      payload: element
    })
    dispatch({ type: 'SET_PLACE_DETAILS', payload: element })
  }

  async function onFieldSave(field, value) {
    await updateContent({ [field]: value })
  }

  async function onPlaceFieldSave(field, value) {
    await updateContent({ place: {
      update: { [field]: value }
    } })
  }

  async function onLodgingFieldSave(field, value) {
    await updateContent({ lodging: {
      update: { [field]: value }
    } })
  }

  

  return (
      <Card className="placeDetailsCard scrollable">

        <div className="d-flex align-items-start p-3 justify-content-between border-bottom">
          <div className="d-flex align-items-start justify-content-start">
            <IconDetails details={state.placeDetails} onFieldSave={onFieldSave} />
            <Editable content={name} field="name" onSave={onFieldSave}>
              <h3 className="mb-0">{name}</h3>
            </Editable>
          </div>
          <Button size="lg" close aria-label="Close details" onClick={close} />
        </div>

        <Photos details={state.placeDetails} />

        <Section title="Note" open={true} hide={fromSearch}>
          <Note details={state.placeDetails} onFieldSave={onFieldSave} />
        </Section>

        {directions && <ItineraryDetails />}

        <Section title="Dates" open={true} hide={type !== 'GROUP' || isShared}>
          <DatesDetails details={state.placeDetails} onFieldSave={onFieldSave} />
        </Section>

        <Section title="Nights" open={true} hide={type !== 'GROUP' || isShared}>
          <Nights details={state.placeDetails} onFieldSave={onFieldSave} />
        </Section>

        <Section title="Location details" open={true} hide={!place}>
          <Place details={state.placeDetails} onPlaceFieldSave={onPlaceFieldSave} />
        </Section>
        
        <Lodging details={state.placeDetails} onLodgingFieldSave={onLodgingFieldSave} />
        
        <Section title="Marker Color" open={false} hide={isShared || fromSearch}>
          <ColorsDetails details={state.placeDetails} onFieldSave={onFieldSave} />
        </Section>

        <Section title="Delete" open={false} hide={isShared || fromSearch}>
          <Delete details={state.placeDetails} close={close} />
        </Section>

        {!!fromSearch && !isShared && <Button color="primary" onClick={createElement} className="m-3">
          Add to trip!
        </Button>}

      </Card>
  );
};

export default PlaceDetailsCard;
