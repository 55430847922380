import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import {Context} from "../../utils/store/index"

const BaseModal = props => {
  const [state, dispatch] = useContext(Context);
  const { 
    name,
    title,
    body,
    actionText = "Submit",
    actionColor = "primary",
  } = props;

  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (!!state.modal && !modal && state.modal.name == name) {
      setModal(true);
    } else if (!state.modal && modal) {
      setModal(false);
    }
  }, [state.modal]);

  const close = () => {
    setModal(false);
    dispatch({ type: 'MODAL', payload: null })
  }

  const onComplete = () => {
    if (state.modal.onComplete) {
      state.modal.onComplete();
    }
    close()
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={close}>
        <ModalHeader toggle={close}>{title}</ModalHeader>
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={close}>
            Cancel
          </Button>
          {" "}
          <Button color={actionColor} onClick={onComplete}>
            {actionText}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BaseModal;
