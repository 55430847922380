import React, { useState, useEffect } from 'react'
import { DateRangePicker, SingleDatePicker, DayPickerRangeController, ANCHOR_RIGHT, HORIZONTAL_ORIENTATION } from 'react-dates'
import moment from 'moment'

import { isShared } from "../../../utils/share"

const DatesDetails = ({ details, onFieldSave }) => {
    console.log("DATE DETAILS", details)
    const [startDate, setStartDate] = useState(details.startDate ? moment(details.startDate) : null)
    const [endDate, setEndDate] = useState(details.endDate ? moment(details.endDate) : null)
    const [focusedInput, setFocusedInput] = useState()

    useEffect(() => {
        setStartDate(details.startDate ? moment(details.startDate) : null)
        setEndDate(details.endDate ? moment(details.endDate) : null)
    }, [details.startDate, details.endDate])

    function setNewDates({ startDate, endDate }) {
        console.log('DATES', startDate, endDate)
        setStartDate(startDate)
        setEndDate(endDate)
        if (startDate && startDate != details.startDate) onFieldSave('startDate', startDate)
        if (endDate && endDate != details.endDate) onFieldSave('endDate', endDate)
    }

    return (
        <div className="mb-3">
            <DateRangePicker
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={setNewDates} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                showClearDates={true}
                minimumNights={0}
                small={true}
                appendToBody={true}
                anchorDirection={ANCHOR_RIGHT}
                orientation={HORIZONTAL_ORIENTATION}
                />
        </div>
        
    )
}

export default DatesDetails