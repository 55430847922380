import React from 'react'
import StarRatings from 'react-star-ratings'

import Editable from "../../Tools/Editable"

const Place = ({ details: { place }, onPlaceFieldSave }) => {

    return (
        <>
          <Editable content={place.address} field="address" onSave={onPlaceFieldSave}>
            <p className="mb-0">{place.address}</p>
          </Editable>
            
            {!!place.rating && <>
              <p className="mb-0">
                <StarRatings
                  rating={place.rating}
                  starRatedColor="#FFC837"
                  numberOfStars={5}
                  starSpacing="0px"
                  starDimension="15px"
                />
                <small>({place.user_ratings_total} people)</small>
              </p>
            </>}

            <a href={place.googleMapsUrl} target="_blank">View on Google Maps</a>
        </>
    )
}

export default Place