import React, { useEffect, useContext } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import Home from "./views/Home";
import Profile from "./views/Profile";
import Trips from "./views/Trips";
import Trip from "./views/Trip";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
import Store, {Context} from './utils/store'
import Modals from "./components/Modals/modals"
import { getUser } from "../../shared/api/user"

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { loading, user } = useAuth0();

  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    async function fetchData() {
      if (user && !state.user) {
        const data = await getUser(user)
        console.log("User from server", data)
        dispatch({ type: 'SET_USER', payload: data })
      }
    }
    fetchData();
  }, [user, state])

  if (loading) {
    console.log("LOADING App.js")
    return <Loading />;
  }

  return (
   <>
      <Modals />

      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Home} />
          <PrivateRoute path="/trips" component={Trips} />
          <PrivateRoute path="/trip/:id" component={Trip} />
          <PrivateRoute path="/profile" component={Profile} />
          <Route path="/share/:id" component={Trip} />
        </Switch>
      </Router>

      
    </>
  );
};

export default App;
