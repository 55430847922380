import React, { useEffect, useState, useContext } from "react";
import { Input } from "reactstrap";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";

const LocationSearch = props => {
  const [address, setAddress] = useState(props.address);

  useEffect(() => {
    handleSelect(props.address)
    console.log('ADDR', props)
  }, [props.address])

  const handleChange = address => {
    setAddress(address);
  };

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        var service = new window.google.maps.places.PlacesService(
          document.createElement("div")
        );
        service.getDetails(
          {
            placeId: results[0].place_id
          },
          function(place, status) {
            if (props.onSelected)
              props.onSelected(
                {
                  address: place.formatted_address,
                  location: {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                  },
                  place
                },
                place
              );

            setAddress(place.formatted_address);
          }
        );
      })
      .catch(error => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      {...props}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Input
            {...getInputProps({
              placeholder: "Search Places ...",
              className: "location-search-input"
            })}
          />
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearch;
