import Axios from "axios"
import { baseUrl } from "./utils" 

export const addElement = async (tripId, type, payload, parent = null) => {

    console.log('ADD', tripId, type, payload, parent)

    let base = {
        index: payload.index,
        type: type,
        subtype: payload.subtype,
        name: payload.name,
        note: payload.note || null,
        icon: payload.icon || null,
        photos: payload.photos ? {
            create: payload.photos
        } : null,
        parent: null,
        childs: {
            create: []
        }
    }

    if (type !== "GROUP" && parent) {
        base.parent = { connect: { id: parent.id } } 
    }

    if (type == "GROUP") {
        base.icon = "fas fa-layer-group"
    }

    if (type == "NOTE") {
        base.icon = "fas fa-sticky-note"
    }

    if (type == "PLACE") {
        base.icon = "fas fa-map-marker-alt"
        base.place = {
            create: {
                address: payload.place.address,
                location: {
                    create: payload.place.location
                },
                placeId: payload.place.placeId,
                rating: payload.place.rating,
                googleMapsUrl: payload.place.googleMapsUrl,
                user_ratings_total: payload.place.user_ratings_total
            }
        }
    }

    if (type == "DIRECTIONS") {
        base.icon = "fas fa-car"
        base.directions = {
            create: {
                mode: 'DRIVING',
                from: null,
                from_name: "",
                to: null,
                to_name: "",
                duration: "0 min",
                distance: "0 km",

            }
        }
    }

    if (type == "NOTE" && payload.subtype == "LODGING") {
        base.icon = "fas fa-bed"
        base.lodging = {
            create: {
                url: "https://aibnb.ca",
                nights: parent && parseInt(parent.nights) ? parseInt(parent.nights) : 1,
                options: {
                    create: []
                }
            }
        }
    }

    const { data } = await Axios.post(
        baseUrl + "/trip-element-create",
        {
            tripId,
            element: base
        }
    )

    return data
}

export const updateElement = async (id, payload) => {

    console.log('UPDATE', id, payload)

    const { data } = await Axios.post(
        baseUrl + "/trip-element-update",
        {
            id,
            element: payload
        }
    )

    return data
}

export const batchUpdateIndexes = async (id, elements) => {

    console.log('BATCH UPDATE INDEXES', id, elements)

    const { data } = await Axios.post(
        baseUrl + "/trip-element-batch-update-index",
        {
            tripId: id,
            elements: elements
        }
    )

    return data
}

export const deleteElement = async (tripId, id, element) => {

    console.log('DELETE', id)

    const { data } = await Axios.post(
        baseUrl + "/trip-element-delete",
        {
            tripId,
            id,
            element
        }
    )

    return data
}


export const getDirections = async (directionsId) => {

    const { data } = await Axios.get(
        baseUrl + "/trip-get-directions?id=" + directionsId
      )

    return data.directions
}