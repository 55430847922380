import React, { useEffect, useState, useContext } from "react";
import { Card, CardTitle, Collapse, Button, Badge } from "reactstrap";
import { ReactSortable, Sortable, Swap } from "react-sortablejs";
import moment from "moment";

import { batchUpdateIndexes } from "../../api/elements";
import { Context } from "../../../web/src/utils/store";
import { updateMarkerColor } from "../Map/MapRenderer";
import { isShared } from "../../../web/src/utils/share"

Sortable.mount(new Swap());

const ElementList = ({ elements, parent }) => {
  const [state, dispatch] = useContext(Context);
  const [list, setList] = useState(elements);

  React.useEffect(() => {
    setList(elements);
  }, [elements]);

  if (!list) return null;

  async function processReorder(data) {
    if (JSON.stringify(list) == JSON.stringify(data)) {
      setList(data);
      return;
    }

    let elementsToUpdate = new Set();

    if (!data) return null;

    data = data.map((elem, index) => {
      if (elem.index != index + 1) {
        elementsToUpdate.add({
          id: elem.id,
          index: index + 1,
          lastParent: elem.parent ? elem.parent.id : null,
          parent: parent ? parent.id : null
        });
      }

      if (elem.parent != parent) {
        updateMarkerColor(elem.id, parent ? parent.color : null);
      }

      return {
        ...elem,
        index: index,
        parent: parent ? parent.id : null
      };
    });

    setList(data);

    if (elementsToUpdate.size > 0) {
      console.log("ELEMS TO UPDATE", elementsToUpdate);
      const {
        trip: { elements }
      } = await batchUpdateIndexes(state.trip.id, Array.from(elementsToUpdate));
      dispatch({ type: "SET_ELEMENTS", payload: elements });
    }
  }

  return (
    <ReactSortable
      list={list}
      setList={processReorder}
      group="shared-group-name"
      className="sortable-group"
      delayOnTouchStart={true}
      disabled={isShared}
    >
      {list.map((elem, index) => (
        <div key={elem.id}>
          {elem.type == "GROUP" && (
            <Group elem={elem} dispatch={dispatch} parent={parent} />
          )}
          {elem.type != "GROUP" && (
            <Card
              className="mb-2"
              onClick={() =>
                dispatch({ type: "SET_PLACE_DETAILS", payload: elem })
              }
            >
              {elem.type == "PLACE" && <Place elem={elem} parent={parent} />}
              {elem.type == "NOTE" && elem.subtype != "LODGING" && <Note elem={elem} parent={parent} />}
              {elem.type == "NOTE" && elem.subtype == "LODGING" && <Lodging elem={elem} parent={parent} />}
              {elem.type == "DIRECTIONS" && (
                <Itinerary elem={elem} parent={parent} />
              )}
            </Card>
          )}
        </div>
      ))}
    </ReactSortable>
  );
};

const Group = ({ elem, dispatch, parent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const icon = elem.icon ? elem.icon : "fas fa-layer-group";

  return (
    <>
      <Card className="mb-2 py-1 px-2">
        <CardTitle
          className="m-2 pointer"
          onClick={() => dispatch({ type: "SET_PLACE_DETAILS", payload: elem })}
        >
          <i
            className={icon}
            style={{ color: elem.color || (parent && parent.color) || "#000" }}
          ></i>{" "}
          {elem.name}
        </CardTitle>

        <div>
          {elem.startDate && (
            <Badge pill>
              {moment(elem.startDate).format("MMM Do")}
              {elem.endDate && elem.startDate != elem.endDate && ` - ${moment(elem.endDate).format("MMM Do")}`}
            </Badge>
          )}

          {elem.nights && elem.nights.length > 0 && (
            <Badge pill color="primary" className="ml-1">{elem.nights} nights</Badge>
          )}
        </div>

        <Collapse isOpen={isOpen} className="mt-2">
          <ElementList elements={elem.childs} parent={elem} />
        </Collapse>

        <Button color="link" onClick={toggle} className="text-left">
          <small>
            {isOpen ? "Hide elements" : `Show ${elem.childs.length} elements`}
          </small>
        </Button>
      </Card>
    </>
  );
};

const Place = ({ elem, parent }) => {
  const icon = elem.icon ? elem.icon : "fas fa-map-marker-alt";
  return (
    <>
      <CardTitle className="m-2 pointer">
        <i
          className={icon}
          style={{ color: elem.color || (parent && parent.color) || "#000" }}
        ></i>{" "}
        {elem.name}
      </CardTitle>
    </>
  );
};

const Note = ({ elem, parent }) => {
  const icon = elem.icon ? elem.icon : "fas fa-sticky-note";
  return (
    <>
      <CardTitle className="m-2 pointer">
        <i
          className={icon}
          style={{ color: elem.color || (parent && parent.color) || "#000" }}
        ></i>{" "}
        {elem.name}
      </CardTitle>
    </>
  );
};

const Lodging = ({ elem, parent }) => {
  const icon = elem.icon ? elem.icon : "fas fa-sticky-note"
  let min = Infinity, max = 0
  for (let option of elem.lodging.options) {
    if (option.price !== 0 && option.price < min) min = option.price
    if (option.price !== 0 && option.price > max) max = option.price
  }
  return (
    <>
      <CardTitle className="m-2 pointer">
        <i
          className={icon}
          style={{ color: elem.color || (parent && parent.color) || "#000" }}
        ></i>{" "}
        {elem.name} &nbsp;
        {min !== Infinity && <span>({min}$ - {max}$/night)</span>}
      </CardTitle>
    </>
  );
};

const Itinerary = ({ elem, parent }) => {
  let icon = "fas fa-car";
  if (elem.icon) icon = elem.icon;
  else if (elem.directions.mode == "TRANSIT") icon = "fas fa-bus";
  else if (elem.directions.mode == "WALKING") icon = "fas fa-walking";
  else if (elem.directions.mode == "BICYCLING") icon = "fas fa-biking";
  return (
    <>
      <CardTitle className="m-2 pointer">
        <i
          className={icon}
          style={{ color: elem.color || (parent && parent.color) || "#000" }}
        ></i>{" "}
        {elem.name} ({elem.directions.duration})
      </CardTitle>
    </>
  );
};

export default ElementList;
