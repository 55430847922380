import React, { useState, useEffect } from 'react'
import { TwitterPicker } from 'react-color';
import { updateMarkerColor } from "../../../../../shared/components/Map/MapRenderer"

import { isShared } from "../../../utils/share"

const colors = [
    '#FF6900',
    '#FCB900',
    '#7BDCB5',
    '#00D084',
    '#8ED1FC',
    '#0693E3',
    '#ABB8C3',
    '#EB144C',
    '#F78DA7',
    '#9900EF'
]

const baseColor = "#fff"

const ColorsDetails = ({ details, onFieldSave }) => {
    const defaultColor = details.color ? details.color : baseColor
    const [color, setColor] = useState(defaultColor)
    const [isOpen, setOpen] = useState(false)

    useEffect(() => {
        setColor(details.color ? details.color : baseColor)
    }, [details.color])

    if (isShared) return null

    function setNewColor(newColor) {
        console.log('NEW COLORS', newColor)
        setColor(newColor.hex)
        onFieldSave('color', newColor.hex)
        setOpen(false)

        if (details.childs) {
            for (let child of details.childs) {
                updateMarkerColor(child.id, newColor.hex)
            }
            
        }
    }

    function toggle() {
        setOpen(!isOpen)
    }

    return (
        <TwitterPicker
            triangle="hide"
            color={color}
            onChangeComplete={setNewColor}
            colors={colors} />
    )
}

export default ColorsDetails