import Axios from "axios"
import { baseUrl } from "./utils" 

export const addListElement = async (itemField, item, parentType, parentId, elementId) => {

    console.log('ADD LIST OPTION', itemField, item, parentType, parentId, elementId)

    let content = {
        action: 'ADD',
        itemField,
        item,
        parentType,
        parentId,
        elementId
    }

    const { data } = await Axios.post(
        baseUrl + "/trip-element-list",
        content
    )

    return data
}

export const updateListElement = async (itemType, item, elementId) => {

    console.log('ADD LIST OPTION', itemType, item, elementId)

    let content = {
        action: 'UPDATE',
        itemType,
        item,
        elementId
    }

    const { data } = await Axios.post(
        baseUrl + "/trip-element-list",
        content
    )

    return data
}
