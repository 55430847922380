function moduleExist(moduleName) {
    try {
        let path = require.resolve(moduleName)
        return true
    } catch(e) {
        return false
    }
}

function moduleName(moduleName) {
    if (!moduleExist) return {}
    try {
        return require(moduleName)
    } catch(e) {
        return null
    }
}

export const baseUrl = (
    typeof window == 'undefined' || typeof window.location == 'undefined' ?  
    (moduleExist("expo-constants") && __DEV__ ? `http://${moduleName("expo-constants").manifest.debuggerHost.split(':').shift()}:8888` : "https://travelplanner.lbaphotographie.ca") : 
    window.location.origin)
    + "/.netlify/functions"