import React, { useState, useEffect } from 'react'
import IconPicker from "../../Tools/IconPicker"

import { isShared } from "../../../utils/share"

const IconDetails = ({ details, onFieldSave }) => {
    const [icon, setIcon] = useState(details.icon)

    useEffect(() => {
        setIcon(details.icon)
    }, [details.icon])

    if (isShared) return null

    function iconPickHandler(newIcon) {
        console.log('NEW ICON', newIcon)
        setIcon(newIcon.prefix + ' fa-' + newIcon.iconName)
        onFieldSave('icon', newIcon.prefix + ' fa-' + newIcon.iconName)
    }

    return (
        <div className="mr-3 mt-1 pointer">
            <IconPicker pickIcon={iconPickHandler} icon={icon} color={details.color} />
        </div>
        
    )
}

export default IconDetails