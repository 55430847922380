import React from "react";

import { Container } from "reactstrap"

import NavBar from "./NavBar"
import Footer from "./Footer"

const Layout = ({ children }) => {

  return (
    <div id="app" className="d-flex flex-column h-100">
        <NavBar className="position-absolute w-100" />
        {children}
        <Footer />
    </div>
  );
};

export default Layout;
