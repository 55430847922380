import React, { useState, useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import Axios from "axios";
import {Context} from "../../utils/store"

const AddTrip = (props) => {
  const {
    buttonLabel,
    className
  } = props;

  const [state] = useContext(Context);

  const [modal, setModal] = useState(false);
  const [name, setName] = useState("")

  const toggle = () => setModal(!modal);

  async function SubmitTrip() {
    console.log(state.user)
    await Axios.post(
      window.location.origin + "/.netlify/functions/trip-create",
      {
        owner: state.user.id,
        name
      })
  }

  return (
    <div>
      <Button color="primary" onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Create a trip</ModalHeader>
        <ModalBody>
            <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" id="name" placeholder="enter a name for the trip" onChange={(e) => setName(e.target.value)} value={name} />
            </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            toggle()
            SubmitTrip()
          }}>Create</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AddTrip;