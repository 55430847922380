import React from 'react'
import s from "./styles.module.css"

const Home = props => {
    return (
        <div>
            <div className={s.rightLogo}></div>
            <div className={s.homeContainer}>
                <div className={`${s.leftContent} d-flex justify-content-center flex-column mx-5`}>
                    <h1 className={s.title}>We make <i>travel</i> <br/> planning <b>fun again</b></h1>
                    <p>Our visual planner makes the process dead easy from placing locations on a map to complete day by day schedules. </p>
                </div>
                <div className={s.leftBg}></div>
            </div>
        </div>
    )
}

export default Home