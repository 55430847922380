import React from 'react'

import Editable from "../../Tools/Editable"

const Nights = ({ details: { nights }, onFieldSave }) => {

    return (
        <Editable content={nights} field="nights" onSave={onFieldSave}>
            {nights && nights.length > 0 ? nights : 0} nights
        </Editable>
    )
}

export default Nights