import React, { useContext } from "react";
import { Button } from "reactstrap";

import { deleteElement } from "../../../../../shared/api/elements"
import { removeMarker, removeDirection } from "../../../../../shared/components/Map/MapRenderer"

import {Context} from '../../../utils/store'

const Delete = ({ details, close }) => {
  const [state, dispatch] = useContext(Context);

  const remove = () => {
    const id = details.id;
    const tripId = state.trip.id;
    const type = details.type;
    const element = details;

    const removeFct = async () => {
      close();

      dispatch({ type: "REMOVE_ELEMENT", payload: element });

      if (type == "PLACE") {
        removeMarker(id);
      }

      if (type == "DIRECTIONS") {
        removeDirection(id);
      }

      await deleteElement(tripId, id, element);
      console.log("REMOVED", id);
    };

    dispatch({
      type: "MODAL",
      payload: {
        name: "DELETE",
        onComplete: removeFct
      }
    });
  };

  return (
    <Button color="danger" outline onClick={remove}>
      <i className="fas fa-trash text-color-danger"></i> Delete this element
    </Button>
  );
};

export default Delete;
