import React, { useState, useEffect } from 'react'
import { Collapse, Button } from 'reactstrap';


const Section = ({ children, title, titleContent, open = false, hide = false }) => {
    const [isOpen, setIsOpen] = useState(open);
    const toggle = () => setIsOpen(!isOpen);

    if (hide) return null

    return (
        <div className="border-bottom">
            <div onClick={toggle} className="d-flex justify-content-between align-items-center py-2 px-3 pointer">
                <h5 className="mb-0">{title}</h5>
                <div>
                    <div>{titleContent}</div>
                    <i className={`fas fa-chevron-${isOpen ? 'up' : 'down'}`}></i>
                </div>
            </div>
            <Collapse isOpen={isOpen}>
                <div className="pt-1 pb-2 px-3">
                    { children }
                </div>
            </Collapse>
        </div>
        
    )
}

export default Section